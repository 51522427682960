* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'NeueHaasDisplay-Light';
  src: url('../fonts/NeueHaasDisplay-Light.ttf');
}

@font-face {
  font-family: 'NeueHaasDisplay-Mediu';
  src: url('../fonts/NeueHaasDisplay-Mediu.ttf');
}

@font-face {
  font-family: 'NeueHaasDisplay-Roman';
  src: url('../fonts/NeueHaasDisplay-Roman.ttf');
}

@font-face {
  font-family: 'NeueHaasGrotesk-Roman';
  src: url('../fonts/NeueHaasGrotesk-Roman.otf');
}

@font-face {
  font-family: 'TitlingGothicFBCompressed-Medium';
  src: url('../fonts/TitlingGothicFBCompressed-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Bud Light Easy Cond';
  font-weight: 400;
  src: local('BudLightEasyCond-Book'), url('../fonts/BudLightEasyCond-Book.otf') format('truetype');
}

@font-face {
  font-family: 'Bud Light Easy Cond';
  font-weight: 800;
  src: local('BudLightEasyCond-XBold'),
    url('../fonts/BudLightEasyCond-XBold.otf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplay-Pro';
  src: url('../fonts/NeueHaasDisplay-Pro.ttf');
}

@font-face {
  font-family: 'TitlingGothicFBNarrow-Standard';
  src: url('../fonts/TitlingGothicFBNarrow-Standard.woff') format('woff');
}

@font-face {
  font-family: 'TitlingGothicFBNarrow-Light';
  src: url('../fonts/TitlingGothicFBNarrow-Light.ttf');
}

@font-face {
  font-family: 'TitlingGothicFBSkyline-Medium';
  src: url('../fonts/TitlingGothicFBSkyline-Medium.otf') format('otf');
}

@font-face {
  font-family: 'TitlingGothicFBSkyline-Medium';
  src: url('../fonts/TitlingGothicFBSkyline-Medium.woff') format('woff');
}

@font-face {
  font-family: 'TitlingGothicFBSkyline-Medium';
  src: url('../fonts/TitlingGothicFBSkyline-Medium.woff2') format('woff2');
}

.grecaptcha-badge {
  visibility: hidden;
}

.responsive-hide {
  display: block;
}

.responsive-show {
  display: none !important;
}

.responsive-flex-show {
  display: none !important;
}
