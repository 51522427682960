.page-not-found-container {
  width: 100%;
  height: calc(100vh / 2);
  background-color: #fff;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: #348ccb;
    font-size: 64px;
    font-family: 'TitlingGothicFBCompressed-Medium';
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    @media (max-width: 992px) {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.48px;
    }
  }
}
