.website-container {
  width: 100%;
}

#root {
  position: relative;
}

.header {
  background-color: #348ccb;
  height: 72px;
  width: 100%;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media (max-width: 600px) {
      justify-content: center;
    }
    .header-left-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .menu-box {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: none;
        }
        a {
          color: #fff;
          font-size: 14px;
          font-family: 'TitlingGothicFBNarrow-Standard';
          font-style: normal;
          font-weight: 450;
          line-height: 20px;
          letter-spacing: 1.68px;
          text-transform: uppercase;
          margin-left: 32px;
          text-decoration: none;
          @media (max-width: 992px) {
            font-size: 12px;
            margin-left: 15px;
          }
        }
      }
      .logo-box {
        height: 32px;
        cursor: pointer;
      }
    }
    .header-sign-in-container {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      .header-sign-in {
        height: 32px;
        border-radius: 32px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 1px 16px 0;
        p {
          color: #348ccb;
          text-align: center;
          font-size: 14px;
          font-family: 'TitlingGothicFBNarrow-Standard';
          font-style: normal;
          font-weight: 450;
          line-height: normal;
          letter-spacing: 1.68px;
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .header-discord {
        margin-left: 12px;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
    }
    .burger-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
      div {
        background-color: #fff;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        &:last-child {
          margin-top: 8px;
        }
      }
    }
  }
}

.footer {
  background-color: #348ccb;
  width: 100%;
  padding: 64px 0;
  .footer-navigation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      align-items: flex-start;
    }
    .footer-menu-links {
      display: flex;
      margin-right: 15px;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        margin-right: 0;
        flex-direction: column;
      }
      a {
        color: rgba(255, 255, 255, 0.75);
        font-size: 16px;
        font-family: 'NeueHaasGrotesk-Roman';
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-decoration: none;
        &:not(:last-child) {
          margin-right: 40px;
        }
        @media (max-width: 768px) {
          margin-right: 0;
          &:not(:first-child) {
            margin-top: 12px;
          }
        }
      }
    }
    .footer-social-links {
      display: flex;
      align-items: center;
      a {
        &:not(:first-child) {
          margin-left: 24px;
        }
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
  }
  .footer-team-box {
    padding: 16px 0;
    border-top: 1px solid #ffffff33;
    border-bottom: 1px solid #ffffff33;
    margin: 17px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    img {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      object-fit: contain;
      margin-right: 33px;
      @media (max-width: 768px) {
        width: 44px;
        height: 44px;
        min-width: 44px;
        min-height: 44px;
        margin-right: 40px;
      }
    }
  }
  .footer-copyright {
    margin-top: 32px;
    margin-bottom: 25px;
    p {
      margin-top: 0;
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.75);
      font-size: 12px;
      font-family: 'NeueHaasGrotesk-Roman';
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .footer-images {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 21px;
    img {
      object-fit: contain;
      &:first-child {
        width: 140px;
        height: 144px;
        @media (max-width: 768px) {
          width: 84px;
          height: 87px;
        }
      }
      &:last-child {
        width: 195px;
        height: 82px;
        margin-left: 64px;
        @media (max-width: 768px) {
          width: 117px;
          height: 49px;
          margin-left: 39px;
        }
      }
    }
  }
}
