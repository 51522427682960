.wrapped-box {
  display: flex;
  height: 100vh;
  @media (max-width: 992px) {
    display: block;
  }
  img {
    width: 80%;
    object-fit: cover;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .playing-box {
    background-color: #338bcb;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 992px) {
      height: 100vh;
    }
    img {
      width: 309px;
      height: 75px;
    }
    h1 {
      margin-top: 50px;
      color: var(--font-color-dark-surface-primary, #fff);
      text-align: center;
      font-family: 'TitlingGothicFBCompressed-Medium';
      font-size: 54px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 48px;
      }
    }
    p {
      color: var(--font-color-dark-surface-primary, #fff);
      text-align: center;
      font-family: 'NeueHaasDisplay-Roman';
      font-size: 17px;
      font-style: normal;
      line-height: 28px; /* 164.706% */
      text-transform: uppercase;
      margin-bottom: unset;
      @media (max-width: 992px) {
        font-size: 12px;
        padding: 20px;
        br {
          display: none;
        }
      }
    }
    button {
      border-radius: 48px;
      background: var(--greys-white, #fff);
      border: 0;
      width: 188px;
      height: 48px;
      color: var(--New-Light-Blue-348CCB, #348ccb);
      text-align: center;
      font-family: 'NeueHaasDisplay-Roman';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 2.24px;
      text-transform: uppercase;
      margin-top: 50px;
    }
  }
}
